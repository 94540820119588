import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderIndex from "../components/header-index"
import BodyWrapper from "../components/body-wrapper"

const SecondPage = () => (
  <Layout>
    <SEO title="Impressum" />

    <HeaderIndex rootLink={true} />
    <BodyWrapper>
      <h1>Impressum</h1>
      <p>
        Nicole Behnke
        <br />
        Landsberger Allee 157
        <br />
        10369 Berlin
      </p>
      <p>
        Steuernummer: 32/222/00418
        <br />
        E-Mail: <a href="mailto:hallo@nicolebehnke.de">hallo@nicolebehnke.de</a>
      </p>
    </BodyWrapper>
  </Layout>
)

export default SecondPage
